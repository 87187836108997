import React from "react";

import Head from "./Header.module.scss";

const Header = () => {
  return (
    <div className={Head.main}>
      <div className={Head.navholder}>
        <div className={Head.leftside}>
          <h1 className={Head.heading}>SHARATH VASAN</h1>
        </div>
        <div className={Head.rightside}>
          <h2 className={Head.menulink}>Blogs</h2>
          <h2 className={Head.menulink}>Wildlife</h2>
        </div>
      </div>
    </div>
  );
};

export default Header;
