import AppStyles from "./App.module.scss";

import HomePage from "./pages/Home/Home";

function App() {
  return (
    <div className={AppStyles.App}>
      <HomePage />
    </div>
  );
}

export default App;
