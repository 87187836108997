import React from "react";

import Header from "../../components/Header/Header";
import FrontImage from "../../assets/images/imagedev.jpg";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

import Home from "./Home.module.scss";

const HomePage = () => {
  return (
    <div className={Home.main}>
      <Header />
      <div className={Home.mainholder}>
        <div className={Home.leftimage}>
          <img className={Home.frontimage} src={FrontImage} alt="frontimage" />
        </div>
        <div className={Home.rightcontent}>
          <div className={Home.introholder}>
            <h3 className={Home.firstheading}>Hi! I am Sharath Vasan</h3>
            <div className={Home.socialiconsholder}>
              <a
                href="https://github.com/SharathVasan"
                target="blank"
                className={Home.anchortag}
              >
                <GitHubIcon className={Home.socialicon} color="#aa1111" />
              </a>
              <a
                href="https://www.linkedin.com/in/sharath-vasan/"
                target="blank"
                className={Home.anchortag}
              >
                <LinkedInIcon className={Home.socialicon} />
              </a>
              <a
                href="https://www.instagram.com/sharath__vasan/"
                target="blank"
                className={Home.anchortag}
              >
                <InstagramIcon className={Home.socialicon} />
              </a>
            </div>
            <p className={Home.intropara}>
              A Developer and a Photographer. Feel free to checkout your
              interest space below.
            </p>
          </div>
          <div className={Home.buttonsholder}>
            <button className={Home.spacebutton}>Developer's Space</button>
            <button className={Home.spacebutton}>Photography Space</button>
            <button className={Home.spacebutton}>General Space</button>
          </div>
        </div>
      </div>
      <div className={Home.secondsection}>
        <h2 className={Home.spaceheading}>Developer's Space</h2>
        <p className={Home.whiteintopara}>
          Welcome to Developer's space. This space is soley about my
          professional carrer as a developer, portfolio of the skills that I
          have and also few blog articles which showcases solutions to the
          problems that I faced in my worklife.
        </p>
      </div>
    </div>
  );
};

export default HomePage;
